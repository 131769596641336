<template>
  <div class="container" style="padding-top: 80px;">
    <div class="row justify-content-center">
      <div class="col-md-10" >
        <div class="text-center">
          <h3>Sobre</h3>
          <p style="line-height: 2;text-align: justify;">
            Se você é um imigrante nos Estados Unidos ou pretende residir na Americana, existem muitos tipos diferentes de
            vistos, e o processo de mudança de status pode ser complicado. Felizmente, existem organizações que podem
            fornecer assistência. A Amariconelli pode ajudá-lo a entender os diferentes tipos de vistos e os requisitos
            para cada um. Também podemos ajudá-lo a preencher a documentação necessária e arquivá-la nas agências
            governamentais apropriadas. Com a nossa ajuda, você pode garantir que seu status de imigração seja atualizado
            corretamente e evitar atrasos ou complicações. Mudança de status é um termo usado pelos Serviços de Cidadania
            e Imigração dos Estados Unidos (USCIS) para se referir ao processo de mudança de uma categoria de visto de
            não-imigrante para outra. As razões mais comuns para “mudança de status” são casamento ou emprego. A mudança
            de status pode ser registrada online ou por correio, e o processo geralmente leva vários meses. Existem
            diferentes tipos de Mudança de Status, e cada tipo tem seu próprio conjunto de requisitos. Por exemplo, a
            “Mudança de Status” do visto de turista B-2 para o visto de estudante F-1 exige que o requerente apresente
            comprovação de suporte financeiro, comprovante de admissão em uma escola certificada pelo SEVP e um Formulário
            I-20 preenchido. A mudança de status pode ser um processo complexo e é importante consultar um advogado de
            imigração antes de preencher qualquer documento.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default { 
  components: {

  }
}
</script>