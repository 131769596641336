<template>
    <div class="container" style="padding-top: 80px;">
        <h3 class="text-center">F1/M1 - Estudante (Curso de Inglês - acadêmico ou vocacionais)</h3>
        <div class="d-flex justify-content-center">
            <div style="max-width: 800px; text-align: justify;">
                <p style="line-height: 2;text-align: justify;">
                    O visto F1 é o mais comum para quem quer estudar nos Estados Unidos. Se você quer estudar em curso de
                    graduação em uma Universidade Americana, fazer curso de inglês em uma instituição desse tipo ou
                    participar de um curso intensivo de inglês em uma escola de idiomas, deve solicitar o visto F1. Para
                    solicitar esse visto, você já deve ter a admissão no curso desejado. SEVP é o Programa para Estudantes e
                    Visitantes Intercambistas, que é ligado ao Departamento de Segurança Interna dos EUA, para gerenciar
                    estudantes estrangeiros e intercambiar visitantes nos Estados Unidos por meio do Student and Exchange
                    Visitor Information System (SEVIS).
                </p>
            </div>
        </div>
        <div class="buttons-container mt-4">
            <div class="buttons-container">
                <a class="btn btn-success" href="https://wa.me/message/L57OUXXHQ6VUB1" target="_blank">
                    <i class="fab fa-whatsapp" style="font-size: 18px;"></i> Fale Conosco
                </a>
                <router-link to="/contact" class="btn btn-primary">
                    <i class="far fa-envelope" style="font-size: 18px;"></i> Envie um Email
                </router-link>
            </div>
        </div>
        <div class="row justify-content-center mt-5 mb-3">
            <div class="col-md-4">
                <div class="card text-center h-100 d-flex flex-column justify-content-between">
                    <div class="card-body">
                        <div class="mb-3">
                            <i class="fas fa-cogs icon" style="font-size: 2em;"></i>
                        </div>
                        <div class="mb-4">
                            <h5 class="card-title fw-bold">Personalização Sob Medida</h5>
                            <p class="card-text fs-6">Estratégias adaptadas de acordo com suas necessidades específicas</p>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card text-center h-100 d-flex flex-column justify-content-between">
                    <div class="card-body">
                        <div class="mb-3">
                            <i class="fas fa-chart-line icon" style="font-size: 2em;"></i>
                        </div>
                        <div class="mb-4">
                            <h5 class="card-title fw-bold">Proficiência</h5>
                            <p class="card-text fs-6">Direcionamento especializado para otimizar suas oportunidades de êxito
                            </p>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
}
</script>
  
<style scoped>
.buttons-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}
</style>