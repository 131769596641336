<template>

  <div class="container-fluid container-color background-image">
    <div class="container text-center">
      <!-- Seção "Na Amariconelli" -->
      <div class="row card2" style="padding-top: 80px;">
        <div class="col text-center">
          <h3 class="section-title">Na Amariconelli</h3>
          <p class="section-description">
            Nós simplificamos seu processo de solicitação. Sem filas de espera e preços acessíveis para emitir ou renovar
            seu Visto Americano. Veja abaixo os vistos que trabalhamos!
          </p>
        </div>
      </div>

      <div class="row mt-4">
        <!-- Card Turista/Negócios -->
        <div class="col-md-4 mb-4">
          <router-link :to="{ name: 'b1&b2' }" class="card">
            <img src="../assets/tourism.jpg" class="card-img-top" alt="">
            <div class="overlay">
              <h5 class="card-title">B1-B2 - Turista/Negócios</h5>
              <p class="card-text">
                Elaboramos sua solicitação cuidadosamente para o Visto de Turismo e Negócios, cuidando de todas as
                etapas do
                processo, como preenchimento de formulários, cadastro, orientações e pagamento de taxas.
              </p>
              <span class="know-more">Clique e saiba mais...</span>
            </div>
          </router-link>
        </div>

        <!-- Card Estudante -->
        <div class="col-md-4 mb-4">
          <router-link :to="{ name: 'f1&m1' }" class="card">
            <img src="../assets/student2.jpg" class="card-img-top" alt="">
            <div class="overlay">
              <h5 class="card-title">F1/M1 - Estudante (Curso de Inglês - acadêmico ou vocacionais)</h5>
              <p class="card-text">
                Auxiliamos em todo o processo do visto de estudante F1, cuidando de todas as etapas do processo, como
                por
                exemplo: preenchimento de formulários, cadastro, orientações e pagamento de taxas.
              </p>
              <span class="know-more">Clique e saiba mais...</span>
            </div>
          </router-link>
        </div>

        <!-- Card Trabalho -->
        <div class="col-md-4 mb-4">
          <router-link :to="{ name: 'work' }" class="card">
            <img src="../assets/work.jpg" class="card-img-top" alt="">
            <div class="overlay">
              <h5 class="card-title">Trabalho</h5>
              <p class="card-text">
                Auxiliamos no seu Visto de Trabalho nos EUA, cuidando das etapas do seu processo, desde a elegibilidade
                até a
                preparação da documentação, garantindo uma experiência fluida e ágil aumentando suas chances.
              </p>
              <span class="know-more">Clique e saiba mais...</span>
            </div>
          </router-link>
        </div>

        <!-- Card Religioso -->
        <!-- Substitua as informações de imagem e texto conforme necessário -->
        <div class="col-md-4 mb-4">
          <router-link :to="{ name: 'eb4' }" class="card">
            <img src="../assets/mormon.jpg" class="card-img-top" alt="">
            <div class="overlay">
              <h5 class="card-title">EB4 - (Religiosos)</h5>
              <p class="card-text">
                Auxiliamos na sua solicitação para o Visto Religioso, avaliando sua elegibilidade e intenções para
                exercer
                atividades religiosas nos EUA.
              </p>
              <span class="know-more">Clique e saiba mais...</span>
            </div>
          </router-link>
        </div>

        <!-- Card Petições -->
        <!-- Substitua as informações de imagem e texto conforme necessário -->
        <div class="col-md-4 mb-4">
          <router-link :to="{ name: 'petitions' }" class="card">
            <img src="../assets/petition.jpg" class="card-img-top" alt="">
            <div class="overlay">
              <h5 class="card-title">Petições - (Petições familiares ou mudança de status)</h5>
              <p class="card-text">
                Auxiliamos no seu pedido de Petição, cuidando do seu processo, desde a elegibilidade até a preparação da
                documentação, garantindo uma experiência fluida e ágil aumentando suas chances.
              </p>
              <span class="know-more">Clique e saiba mais...</span>
            </div>
          </router-link>
        </div>

        <!-- Card Antecipação de Entrevista -->
        <!-- Substitua as informações de imagem e texto conforme necessário -->
        <div class="col-md-4 mb-4">
          <router-link :to="{ name: 'antecipation' }" class="card">
            <img src="../assets/antecipation.jpg" class="card-img-top" alt="">
            <div class="overlay">
              <h5 class="card-title">Antecipação de Entrevista</h5>
              <p class="card-text">
                Monitoramos 24 horas por dia e 7 dias por semana o calendário do consulado americano com o objetivo de
                encontrar a data mais próxima para a sua entrevista.
              </p>
              <span class="know-more">Clique e saiba mais...</span>
            </div>
          </router-link>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h2 class="card-title text-center">Segurança</h2>
              <hr class="my-4">
              <p>
                Temos o maior respeito com as suas informações pessoais, elas são guardadas com sigilo absoluto. Após a
                conclusão do serviço todos os seus dados são apagados do nosso banco de dados, seguindo as normas de
                segurança N.13.709/2018.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h2 class="card-title text-center mb-4">Porque nos contratar?</h2>
              <hr class="my-4">
              <p>
                Oferecemos o melhor custo-benefício, agilidade no processo e condições especiais para pagamentos de vistos
                superiores ao Turista (B1/B2). Além disso, proporcionamos assistência em cada etapa do processo de
                obtenção
                do visto, garantindo que você tenha todas as informações necessárias.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  components: {
   
  }
}
</script>

<style scoped>
.section-title {
  color: #fff;
  /* Cor azul associada ao Facebook */
  font-size: 2.5rem;
  /* Tamanho da fonte ajustável */
  font-weight: bold;
}

.section-description {
  font-size: 1.25rem;
  /* Tamanho da fonte para o parágrafo */
  color: #fff;
  /* Cor do texto mais escura para melhor legibilidade */
  max-width: 600px;
  /* Largura máxima do parágrafo para evitar linhas muito longas */
  margin: 0 auto;
  /* Centralizar o parágrafo */
  font-weight: bold;
}

.background-image {
  background-image: url('../assets/backgroundHome.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
}

.background-image::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  /* Ajuste a opacidade conforme necessário */
}

.know-more {
  margin-top: 10px;
  font-weight: bold;
  cursor: pointer;
  /* Adicione outros estilos desejados para o texto clicável */
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 10px;
}

.card2 {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Adicione sombra aos cartões */
  margin-bottom: 20px;
  /* Adicione margem inferior para espaçamento entre cartões */
}

.card {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Adicione sombra aos cartões */
  margin-bottom: 20px;
  /* Adicione margem inferior para espaçamento entre cartões */
}

.card img {
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.card:hover {
  transform: scale(1.1);
}

.card:hover .overlay {
  opacity: 1;
}

.btn-primary {
  margin-top: 10px;
}
</style>
