<template>
    <div class="container" style="padding-top: 80px;">
        <h3 class="text-center">EB4 - (Religiosos)</h3>
        <div class="d-flex justify-content-center">
            <div style="max-width: 800px; text-align: justify;">

                <div class="container">
                    <p>Poderão solicitar o visto religioso para os EUA as pessoas com ocupações ligadas diretamente a
                        igrejas.</p>
                    <p>Para se qualificar para um visto de trabalho religioso (R), os pastores e/ou trabalhadores religiosos
                        precisarão atender a alguns requisitos como:</p>
                    <ul>
                        <li class="list-item">Ser membro há pelo menos dois anos de uma denominação religiosa que tenha uma
                            organização legítima sem fins lucrativos nos Estados Unidos;</li>
                        <li class="list-item">A organização religiosa para a qual o interessado irá atuar precisa ser isenta
                            de tributação, conforme prevê a seção 501(c) (3) do Código de Imposto de Renda de 1986);</li>
                        <li class="list-item">Deverá exercer a vocação de pastor ou ministro religioso, exercer a função
                            profissional religiosa ou trabalhar em uma organização dentro de uma denominação reconhecida ou
                            para uma organização legítima afiliada a uma denominação religiosa;</li>
                    </ul>
                </div>

            </div>
        </div>
        <div class="buttons-container mt-4">
            <div class="buttons-container">
                <a class="btn btn-success" href="https://wa.me/message/L57OUXXHQ6VUB1" target="_blank">
                    <i class="fab fa-whatsapp" style="font-size: 18px;"></i> Fale Conosco
                </a>
                <router-link to="/contact" class="btn btn-primary">
                    <i class="far fa-envelope" style="font-size: 18px;"></i> Envie um Email
                </router-link>
            </div>
        </div>
        <div class="row justify-content-center mt-5 mb-3">
            <div class="col-md-4">
                <div class="card text-center h-100 d-flex flex-column justify-content-between">
                    <div class="card-body">
                        <div class="mb-3">
                            <i class="fas fa-cogs icon" style="font-size: 2em;"></i>
                        </div>
                        <div class="mb-4">
                            <h5 class="card-title fw-bold">Personalização Sob Medida</h5>
                            <p class="card-text fs-6">Estratégias adaptadas de acordo com suas necessidades específicas</p>
                        </div>
                        <div></div> <!-- Espaço em branco caso o texto termine -->
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card text-center h-100 d-flex flex-column justify-content-between">
                    <div class="card-body">
                        <div class="mb-3">
                            <i class="fas fa-check-circle icon" style="font-size: 2em;"></i>
                        </div>
                        <div class="mb-4">
                            <h5 class="card-title fw-bold">Segurança na Documentação</h5>
                            <p class="card-text fs-6">Deixe toda a burocracia relacionada à documentação conosco.</p>
                        </div>
                        <div></div> <!-- Espaço em branco caso o texto termine -->
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card text-center h-100 d-flex flex-column justify-content-between">
                    <div class="card-body">
                        <div class="mb-3">
                            <i class="fas fa-chart-line icon" style="font-size: 2em;"></i>
                        </div>
                        <div class="mb-4">
                            <h5 class="card-title fw-bold">Proficiência</h5>
                            <p class="card-text fs-6">Direcionamento especializado para otimizar suas oportunidades de êxito
                            </p>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
}
</script>
  
<style scoped>
.buttons-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}</style>